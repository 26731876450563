import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AnimatedCursor from 'react-animated-cursor';

const Home = () => {
  return (
    <section id="home">
        <AnimatedCursor 
          innerSize={10}
          outerSize={10}
          color='250, 177, 225'
          outerAlpha={0.6}
          innerScale={0.7}
          outerScale={3}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.link'
          ]}
        />
        <h2 className='name'>AMRIT GREWAL</h2>
        <h4 className='role'>Full Stack Web Developer</h4>

        <h5 className='bio'>A London-based graduate from HyperionDev's Full Stack Web Development bootcamp, with creative skills and experience in hospitality and finance.</h5>

        <p className='lang'>Proficient in HTML, CSS, JavaScript, React.js, Bootstrap, Node.js and MongoDB.</p>
    </section>
  );
};

export default Home;