import React, { useState } from 'react';
import Header from './components/Header.js';
import About from './components/About.js';
import Projects from './components/Projects.js';
import Contact from './components/Contact.js';
import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import Home from './components/Home';
import light from "./images/light.png";
import dark from "./images/dark.png"

const App = () => {

  const [darkMode, setDarkMode] = useState(true);
  const [theme, setTheme] = useState("light-mode");
  const [footerTheme, setFooterTheme] = useState("light-footer");

  function toggleTheme () {
    setDarkMode(!darkMode);
    if (darkMode === true) {
      setTheme("dark-mode");
      setFooterTheme("dark-footer");
    } else {
      setTheme("light-mode");
      setFooterTheme("light-footer");
    }
  }

  return (
    <>
      <div className={theme}>

        {(darkMode === false) ? 
          <img onClick={() => toggleTheme()} className='theme-icon' src={light}/>
         :
          <img onClick={() => toggleTheme()} className='theme-icon' src={dark}/>
        }

        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <footer className={footerTheme}>
        <p>&copy; {new Date().getFullYear()} Amrit Grewal</p>
      </footer>
    </>
  );
};

export default App;
