import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import portrait from "../images/portrait.JPEG";
import fullshot from "../images/fullshot.JPEG";
import AnimatedCursor from 'react-animated-cursor';

const About = () => {
  return (
    <section id="about">
        <AnimatedCursor 
          innerSize={10}
          outerSize={10}
          color='234, 230, 167'
          outerAlpha={0.6}
          innerScale={0.7}
          outerScale={3}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.link'
          ]}
        />
        <h2 className='title'>ABOUT</h2>

        <Container>
            <Row>
                <Col>
                    <p className='about'>In the last few years, I have gone from working for Bobbi Brown Cosmetics as a Makeup Artist at their Oxford St. counter, to studying full-stack Web Development with HyperionDev.</p>
                </Col>
            </Row>
            <Row>
                <div className="course">
                    <h5 className='course-title'>Full-Stack Web Development Bootcamp</h5>
                    <h6 className='location'>Imperial College London / HyperionDev</h6>
                    <p>Avg Grade: 97% - ranked 1st out of 95 students</p>
                </div>
            </Row>
            <Row>
                <Col>
                    <img className='pic1' src={portrait} />
                </Col>
            </Row>
            <Row>
                <p className='about'>As someone with experience in photography, makeup, and other creative mediums, I enjoy practising front-end development as I get to utilise these skills. However, my course has also equipped me with back-end development capabilities, allowing me to further understand both types of developers.</p>
            </Row>
        </Container>
      

    </section>
  );
};

export default About;