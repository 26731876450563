import React from 'react';
import mail from "../images/mail.png";
import linkedin from "../images/linkedin.png";
import instagram from "../images/instagram.png";
import github from "../images/github.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AnimatedCursor from 'react-animated-cursor';

const Contact = () => {
  return (
    <section id="contact">
        <AnimatedCursor 
          innerSize={10}
          outerSize={10}
          color='122, 157, 161'
          outerAlpha={0.6}
          innerScale={0.7}
          outerScale={3}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.link'
          ]}
        />
        <h2 className='title'>CONTACT</h2>

        <a href="mailto:amrit26.2002@gmail.com">
            <img className="icon" src={mail} />
        </a>

        <a href="https://www.linkedin.com/in/amrit-singh-b5066b201/" target="_blank">
            <img className='icon' src={linkedin} />
        </a>

        <a href="https://www.instagram.com/brownboibarbie/" target="_blank">
            <img className='icon' src={instagram} />
        </a>

        <a href="https://github.com/amrit1-1" target="_blank">
            <img className='icon' src={github} />
        </a>
    </section>
  );
};

export default Contact;
