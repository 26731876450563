import React from 'react';
import AnimatedCursor from 'react-animated-cursor';
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const currentPage = useLocation().pathname;

  return (
    <>
        <header>
            <div className='header'>
                <h1 className="main-title">A.G.</h1>
            </div>

            <div className='links'>
              <Link className={currentPage === '/'? "nav1-active" : "nav1"} to="/">HOME</Link>
              <Link className={currentPage === '/about'? "nav2-active" : "nav2"} to="/about">ABOUT</Link>
              <Link className={currentPage === '/projects'? "nav3-active" : "nav3"} to="/projects">PROJECTS</Link>
              <Link className={currentPage === '/contact'? "nav4-active" : "nav4"} to="/contact">CONTACT</Link>
            </div>
        </header>
    </>
  );
};

export default Header;
